import TrustMarkers from '../TrustMarkers'
import { useFeatureFlags } from 'src/common/ab-testing/context'
import { experimentSectionByVariation } from 'src/common/analytics/consts'
import { featureToggles } from 'src/common/constants/featureToggles'
import Awards from '../Awards'

const TrustMarkerWrapper = ({ trustMarkersCombination }) => {
  if (!trustMarkersCombination || trustMarkersCombination === 'control') {
    return (
      <Awards
        className="px-4 pt-12 lg:py-10"
        desktopTitleClassname="border-b border-gray-270 mb-4"
        isFooter={false}
      />
    )
  }

  return <TrustMarkers trustMarkersCombination={trustMarkersCombination} />
}

export default TrustMarkerWrapper
